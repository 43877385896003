import React, { useEffect, useState, useRef, Fragment, useCallback } from "react";
import axios from "axios";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";

export default function AdminLookupsRTPage() {
    const [lookups, setLookups] = useState([]);
    const [propertyRoomTypes, setPropertyRoomTypes] = useState([]);
    const [unitTypes, setUnitTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [properties, setProperties] = useState([]);

    useEffect(() => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}building_room_types`,
        })
            .then((response) => {
                setPropertyRoomTypes(response.data);

                setLoading(false);
            })
            .catch((error) => {
                alert("Failed to get lookups");
                setLoading(false);
            });
    }, [loading]);

    useEffect(() => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}properties`,
        })
            .then((response) => {
                setProperties(response.data);
            })
            .catch((error) => {
                alert("properties failed");
            });

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}unit_types/0`,
        })
            .then((response) => {
                setUnitTypes(response.data);
            })
            .catch((error) => {
                alert("unit types failed");
            });

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}uc_lookups`,
        })
            .then((response) => {
                setLookups(response.data);
            })
            .catch((error) => {
                alert("Failed to get lookups");
            });
    }, []);

    const handleDelete = (id) => {
        if (window.confirm("Are you sure you want to delete this lookup?")) {
            axios({
                method: "delete",
                url: `${process.env.REACT_APP_API_URL}building_room_type/${id}`,
            })
                .then((response) => {
                    setLoading(true);
                })
                .catch((error) => {
                    alert("Failed to delete lookup");
                });
        }
    };

    return (
        <div className="px-1 sm:px-4 my-2 xl:my-0 xl:p-4 h-screen w-full">
            <div className="flex justify-between mb-8 items-center">
                <h1 className="text-lg font-bold">Lookups</h1>

                <div className="space-x-2">
                    <Link to="/admin_lookups/dashboard" className="bg-primary text-white px-4 py-2 rounded-full">
                        Back to Lookups
                    </Link>

                    <Link to="/bot_flow" className="bg-primary text-white px-4 py-2 rounded-full">
                        Back to Bot Flows
                    </Link>
                </div>
            </div>

            <div className="mb-6">
                <h2 className="text-lg font-semibold text-gray-700 mb-4">Add New Property Room Type</h2>
                <Formik
                    initialValues={{
                        property_id: "",
                        institution_id: "",
                        campus_id: "",
                        budget_id: "",
                        funding_type_id: "",
                        unit_type_id: "",
                    }}
                    // validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                        setSubmitting(true);

                        await axios({
                            method: "post",
                            url: `${process.env.REACT_APP_API_URL}building_room_type/save`,
                            data: values,
                        })
                            .then((response) => {
                                // toast(
                                //     <NotificationToast
                                //         title={"Application Updated"}
                                //         message="Your application has succesfully been updated. Keep an eye out on your email and cellphone for future communication"
                                //     />
                                // );
                                setLoading(true);
                            })
                            .catch((response) => {
                                // toast(<NotificationToast title={"Something went wrong"} message={response.response.data.message} type="error" />);
                                setLoading(true);
                            });
                    }}
                >
                    {({ isSubmitting, values }) => (
                        <Form className="grid grid-cols-3 gap-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Property</label>
                                <Field
                                    name="property_id"
                                    as="select"
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                                    required
                                >
                                    <option value="">Select Property</option>
                                    {properties.map((property) => (
                                        <option key={property.id} value={property.value}>
                                            {property.label}
                                        </option>
                                    ))}
                                </Field>
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700">Institution</label>
                                <Field
                                    name="institution_id"
                                    as="select"
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                                    required
                                >
                                    <option value="">Select Institution</option>
                                    {lookups?.[4]?.map((lookup, index) => (
                                        <option key={lookup.value} value={lookup.value}>
                                            {lookup.label}
                                        </option>
                                    ))}
                                </Field>
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700">Campus</label>
                                <Field
                                    name="campus_id"
                                    as="select"
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                                    required
                                >
                                    <option value="">Select Campus</option>
                                    {lookups?.[5]?.map((lookup, index) => (
                                        <option key={lookup.value} value={lookup.value}>
                                            {lookup.label}
                                        </option>
                                    ))}
                                </Field>
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700">Funding Type</label>
                                <Field
                                    name="funding_type_id"
                                    as="select"
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                                    required
                                >
                                    <option value="">Select Funding Type</option>
                                    {lookups?.[14]?.map((lookup, index) => (
                                        <option key={lookup.value} value={lookup.value}>
                                            {lookup.label}
                                        </option>
                                    ))}
                                </Field>
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700">Budget</label>
                                <Field
                                    name="budget_id"
                                    as="select"
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                                    required
                                >
                                    <option value="">Select Budget</option>
                                    {lookups?.[15]?.map((lookup, index) => (
                                        <option key={lookup.value} value={lookup.value}>
                                            {lookup.label}
                                        </option>
                                    ))}
                                </Field>
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700">Room Type</label>
                                <Field
                                    name="unit_type_id"
                                    as="select"
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                                    required
                                >
                                    <option value="">Select Room Type</option>
                                    {unitTypes
                                        ?.filter((unitType) => unitType.property_id == values.property_id)
                                        .map((unitType) => (
                                            <option key={unitType.value} value={unitType.value}>
                                                {unitType.label}
                                            </option>
                                        ))}
                                </Field>
                            </div>

                            <div className="col-span-3">
                                <button type="submit" disabled={isSubmitting} className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-700">
                                    {isSubmitting ? "Submitting..." : "Add Property Room Type"}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>

            <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Property
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-1/5">
                            Institution
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-1/5">
                            Campus
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-1/5">
                            Funding Type
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-1/5">
                            Budget
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-1/5">
                            Room Type
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-1/5">
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                    {propertyRoomTypes.length > 0 &&
                        propertyRoomTypes.map((lookup, index) => (
                            <tr key={"lookup_" + lookup.id}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{lookup.property?.name}</td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{lookup.institution?.name}</td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{lookup.campus?.name}</td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{lookup.funding_type?.name}</td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{lookup.budget?.name}</td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{lookup.unit_type?.name}</td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    <button onClick={() => handleDelete(lookup.id)}>Delete</button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
}
